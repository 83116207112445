<template>
  <a-modal
    :visible="visible"
    :title="form.id ? '编辑' : '新增'"
    :mask-closable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
      <div v-if="!form.id">
        <a-form-item label="名称" v-bind="validateInfos.name">
          <a-input v-model:value="form.name" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item label="登录密码" v-bind="validateInfos.password">
          <a-input-password
            v-model:value="form.password"
            placeholder="请输入登录密码"
          />
        </a-form-item>
        <a-form-item label="手机号" v-bind="validateInfos.mobile" required>
          <a-input v-model:value="form.mobile" placeholder="请输入手机号" />
        </a-form-item>
        <a-form-item label="邮箱" v-bind="validateInfos.email">
          <a-input v-model:value="form.email" placeholder="请输入邮箱" />
        </a-form-item>
        <a-form-item label="性别">
          <a-radio-group v-model:value="form.sex.code">
            <a-radio value="M">男</a-radio>
            <a-radio value="W">女</a-radio>
          </a-radio-group>
        </a-form-item>
      </div>
      <a-form-item label="组织" v-bind="validateInfos.orgId">
        <a-tree-select
          v-model:value="form.orgId"
          placeholder="请选择组织"
          allow-clear
          :tree-data="orgList"
          :replace-fields="{ key: 'id', value: 'id' }"
        />
      </a-form-item>
      <!-- <a-form-item label="职位">
        <a-input v-model:value="form.stationId" placeholder="请输入职位" />
      </a-form-item> -->
      <a-form-item label="状态">
        <a-switch
          v-model:checked="form.status"
          checked-children="启用"
          un-checked-children="禁用"
        />
      </a-form-item>
      <a-form-item label="描述" v-if="!form.id">
        <a-textarea
          v-model:value="form.workDescribe"
          showCount
          :maxlength="100"
          placeholder="请输入描述"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, toRaw, toRefs, watch } from 'vue';
import { Form, Radio, Switch, TreeSelect } from 'ant-design-vue';
import { validMobile, validEmail } from '@/utils/validation';
import orgApi from '@/api/org';
import userApi from '@/api/user';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    ASwitch: Switch,
    ATreeSelect: TreeSelect,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const form = reactive({
      id: '',
      account: '',
      name: '',
      password: '123456',
      avatar: '',
      sex: { code: 'M', desc: '男' },
      mobile: '',
      email: '',
      status: true,
      orgId: null,
      stationId: '',
      workDescribe: '',
    });
    const state = reactive({
      orgList: [],
    });
    const code = `user:${props.initValue.id ? 'update' : 'add'}`;
    const required = { required: true, message: '不能为空' };
    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      name: [required],
      password: [required, { min: 6, message: '请至少输入6位密码长度' }],
      orgId: [required],
      mobile: [
        {
          validator: async (rule, mobile) => {
            if (mobile) {
              if (validMobile(mobile)) {
                const ur = await userApi.verifyEmailOrPhone('user:add', {
                  mobile,
                });
                return ur && ur.id !== form.id
                  ? Promise.reject('该手机号已使用，请更换其他号码')
                  : Promise.resolve();
              }
              return Promise.reject('请输入正确的手机号');
            }
            return Promise.reject('不能为空');
          },
        },
      ],
      email: [
        {
          validator: async (rule, email) => {
            if (email) {
              if (validEmail(email)) {
                const ur = await userApi.verifyEmailOrPhone('user:add', {
                  email,
                });
                return ur && ur.id !== form.id
                  ? Promise.reject('该邮箱已使用，请更换其他邮箱')
                  : Promise.resolve();
              }
              return Promise.reject('请输入正确的邮箱');
            }
            return Promise.resolve();
          },
        },
      ],
    });
    const handleCancel = () => context.emit('update:visible', false);
    watch(
      () => props.initValue,
      initValue => {
        if (initValue.id) {
          Object.assign(form, initValue);
        } else {
          resetFields();
        }
      }
    );
    orgApi.getList(code).then(data => {
      state.orgList = data;
    });
    return {
      ...toRefs(state),
      form,
      validateInfos,
      handleCancel,
      handleOk: () => {
        validate().then(() => {
          context.emit('fnOk', toRaw(form));
          handleCancel();
        });
      },
    };
  },
};
</script>
