'use strict';

export const mobileRegExp = /^1(3[0-9]|4[01456879]|5[0-3,5-9]|6[2567]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}$/;

export function validMobile(value) {
  return mobileRegExp.test(value);
}

export const emailRegExp = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/;

export function validEmail(value) {
  return emailRegExp.test(value);
}
